import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useApp } from '../contexts/AppContext';

const StaticCanvas = forwardRef((props, ref) => {
    const canvasRef = useRef(null);

    const {
      numDots, staticDigits, setAreDigitsCorrect,
      colorMap, positionBase, digString2Dots
    } = useApp();

    const dotarray = digString2Dots(staticDigits, positionBase[numDots], 20, colorMap);

    useImperativeHandle(ref, () => ({
      captureImage: () => {
        const canvas = canvasRef.current;
        return canvas.toDataURL('image/png');
      },
      getCanvas: () => canvasRef.current // New method to get the canvas element
    }));

    const drawCanvas = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.canvas.width = 400;
      context.canvas.height = 400;
      context.strokeStyle = "grey";
      context.fillStyle = "lightgrey";
      context.lineWidth = 12;
      context.beginPath();
      context.roundRect(8, 8, 384, 384, 16); 
      context.stroke();
      context.fill();
  
      let radius = 24; // Arc radius
      let startAngle = 0; // Starting point on circle
      let endAngle = Math.PI * 2; // End point on circle
      for (let i = dotarray.length-1; i >= 0 ; i--) {
        context.beginPath();
        context.fillStyle = dotarray[i].colour;
        context.arc(dotarray[i].x, dotarray[i].y, radius, startAngle, endAngle);
        context.fill();
        context.closePath();
      }
    };

    useEffect(() => {
      drawCanvas();
      setAreDigitsCorrect(false);
    }, [staticDigits]);

    useEffect(() => {
      drawCanvas();
    }, []);

    return (
      <div>
        <canvas ref={canvasRef} />
      </div>
    );
});

export default StaticCanvas;