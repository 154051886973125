import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const AppContext = createContext();

export function useApp() {
    return useContext(AppContext)
}

const AppContextProvider = (props) => {

  const [searchParams] = useSearchParams();
  const urlRoute = window.location.href;
  // console.log(searchParams); // ▶ URLSearchParams {}
    let numberOfDots = Math.floor(Math.random() * 16) + 6;

  let puzzleCode = '';
  let puzzleCodeEncrypt = '';

  const navigate = useNavigate()

  function createPuzzleId(puzzleCode) {
    var iv = CryptoJS.enc.Hex.parse('101112131415161718191a1b1c1d1e1f');
    var key = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    var encrypted = CryptoJS.AES.encrypt(puzzleCode, key, { iv: iv });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    // console.log(encrypted.toString());
    // console.log(decrypted.toString(CryptoJS.enc.Utf8));
    const puzzleIdEncrypt = encrypted.toString();
    // console.log({puzzleIdEncrypt});
    return puzzleIdEncrypt;
  }

  function getPuzzleCode(encryptedPuzzleCode) {
      var iv = CryptoJS.enc.Hex.parse('101112131415161718191a1b1c1d1e1f');
      var key = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
      var decrypted = CryptoJS.AES.decrypt(encryptedPuzzleCode, key, { iv: iv }); 
      // console.log(decrypted.toString(CryptoJS.enc.Utf8));
      const decryptedPuzzleCode = decrypted.toString(CryptoJS.enc.Utf8);
      // console.log({decryptedPuzzleCode});
      return decryptedPuzzleCode;

  }
    

  if (searchParams.has('puzzleId')) {
    puzzleCodeEncrypt = searchParams.get('puzzleId');
    puzzleCode = getPuzzleCode(puzzleCodeEncrypt);
    numberOfDots = puzzleCode.length;
    // console.log(searchParams.get('puzzleId')); // ▶ "3342444312"
  }
  else if (searchParams.has('puzzleCode')) {
    puzzleCode = searchParams.get('puzzleCode');
    numberOfDots = puzzleCode.length;
    puzzleCodeEncrypt = createPuzzleId(puzzleCode);
    // console.log(searchParams.get('puzzleCode')); // ▶ "3342444312"
  }
  else {
    puzzleCode = generateRandomString(numberOfDots);
    puzzleCodeEncrypt = createPuzzleId(puzzleCode);
    // console.log({puzzleCode, puzzleCodeEncrypt});  
  }


    const [numDots, setNumDots] = useState(numberOfDots);
    const [puzzleId, setPuzzleId] = useState(puzzleCodeEncrypt); 
    const [puzzleDigits, setPuzzleDigits] = useState(generateZeros(numberOfDots));
    const [staticDigits, setStaticDigits] = useState(puzzleCode);
    const [areDigitsCorrect, setAreDigitsCorrect] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [imageName, setImageName] = useState('');
    const [imageSaved, setImageSaved] = useState(false);
    const [moveList, setMoveList] = useState([]); // [ {number: 1, direction: 'up'}, {number: 2, direction: 'down'} ]

    const colorMap = {
        0: 'black',
        1: 'white',
        2: 'red',
        3: 'green',
        4: 'yellow',
        5: 'blue',
        6: 'orange',
        7: 'purple',
        8: 'pink',
        9: 'brown'
        // ... Add colors for other numbers
      };

  useEffect(() => {
    // console.log({puzzleDigits, staticDigits, shareLink, puzzleId});
    
    if (puzzleDigits === staticDigits) {
        setAreDigitsCorrect(true);
        navigate('/outro');
    }
    else {
      setShareLink(urlRoute + "?puzzleId=" + puzzleId);
      // const tempImageName = puzzleId + '.png';
      // setImageName(tempImageName);
      // const tempImagePath = `${IMAGE_DIRECTORY}${tempImageName}`;
      // setImagePath(tempImagePath);
    
      // fetch(tempImagePath)
      //   .then(response => {
      //     if (response.status === 200) {
      //       setImageSaved(true);
      //       // console.log('Image ' + tempImagePath + ' already saved');
      //     } else {
      //       setImageSaved(false);
      //       // console.log('Image ' + tempImagePath + ' not yet saved');
      //     }
      //   })
      //   .catch(error => {
      // console.error('Error checking image:', error);
      //   });
    }
  }, [puzzleDigits, staticDigits]);

      function generateRandomString(numDigits) {
        const partitions = getPartitions(numDigits);
        const chosenPartition = partitions[Math.floor(Math.random() * partitions.length)];
        
        let stringArray = [];
        for (let num of chosenPartition) {
            for (let i = 0; i < num; i++) {
                stringArray.push(num);
            }
        }
      
        // Shuffle the array
        for (let i = stringArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [stringArray[i], stringArray[j]] = [stringArray[j], stringArray[i]];
        }
      
        return stringArray.join("");
      }
      
      function getPartitions(targetSum) {
        function backtrack(remain, current, start, result) {
            if (remain === 0) {
                result.push([...current]);
                return;
            }
            for (let i = start; i <= remain && i <= 9; i++) {
                current.push(i);
                backtrack(remain - i, current, i, result);
                current.pop();
            }
        }
      
        const result = [];
        backtrack(targetSum, [], 1, result);
        return result.filter(part => part.every((num, idx, arr) => arr.indexOf(num) === idx));
      }
      
      
      function digString2Dots (digstring, posBase, multiplier, colArray) {
        const digstringArray = digstring.split('');
        // console.log({digstringArray})
        const returnArray = [];
        for (let index = 0; index < digstringArray.length; index++) {
          const element = {x: multiplier*posBase[index].x, y: multiplier*posBase[index].y, colour: colArray[digstringArray[index]]};
          returnArray.push(element);
          
        }
        return returnArray;
        
      }
      const positionBase = [[],[],[],[],[],[],
        [ // 6
        { x: 10, y: 3},
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9}
        ],
        [ // 7
          { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 8, y: 12},
        { x: 12, y: 12}
      ],
        [ // 8
          { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 10, y: 15}],
      
        [ // 9
          { x: 10, y: 3},
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 10, y: 15}
      ],
      [ // 10
        { x: 10, y: 3},
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 4, y: 12},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 16, y: 12}
      ],
      [ // 11
        { x: 10, y: 3},
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 4, y: 12},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 16, y: 12},
        { x: 10, y: 15}
      ],
      [ // 12
        { x: 10, y: 3},
        { x: 4, y: 6},
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 16, y: 6},
        { x: 6, y: 9},
        { x: 14, y: 9},
        { x: 4, y: 12},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 16, y: 12},
        { x: 10, y: 15}
      ],
      [ // 13
        { x: 10, y: 3},
        { x: 4, y: 6},
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 16, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 4, y: 12},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 16, y: 12},
        { x: 10, y: 15}
      ],
      [ // 14
        { x: 8, y: 3},
        { x: 12, y: 3},
        { x: 6, y: 6},
        { x: 10, y: 6},
        { x: 14, y: 6},
        { x: 4, y: 9},
        { x: 8, y: 9},
        { x: 12, y: 9},
        { x: 16, y: 9},
        { x: 6, y: 12},
        { x: 10, y: 12},
        { x: 14, y: 12},
        { x: 8, y: 15},
        { x: 12, y: 15}
      ], 
      [ // 15
        { x: 10, y: 3}, 
        { x: 8, y: 6},
        { x: 12, y: 6},
        { x: 6, y: 9},
        { x: 10, y: 9},
        { x: 14, y: 9},
        { x: 4, y: 12},
        { x: 8, y: 12},
        { x: 12, y: 12},
        { x: 16, y: 12},
        { x: 2, y: 15},
        { x: 6, y: 15},
        { x: 10, y: 15},
        { x: 14, y: 15},
        { x: 18, y: 15}
    ], 
    [ // 16 
      { x: 8, y: 6},
      { x: 12, y: 6},
      { x: 6, y: 9},
      { x: 10, y: 9},
      { x: 14, y: 9},
      { x: 4, y: 12},
      { x: 8, y: 12},
      { x: 12, y: 12},
      { x: 16, y: 12},
      { x: 6, y: 15},
      { x: 10, y: 15},
      { x: 14, y: 15},
      { x: 4, y: 18},
      { x: 8, y: 18},
      { x: 12, y: 18},
      { x: 16, y: 18}
  ],
  [ // 17

    { x: 10, y: 3},
    { x: 8, y: 6},
    { x: 12, y: 6},
    { x: 6, y: 9},
    { x: 10, y: 9},
    { x: 14, y: 9},
    { x: 4, y: 12},
    { x: 8, y: 12},
    { x: 12, y: 12},
    { x: 16, y: 12},
    { x: 6, y: 15},
    { x: 10, y: 15},
    { x: 14, y: 15},
    { x: 4, y: 18},
    { x: 8, y: 18},
    { x: 12, y: 18},
    { x: 16, y: 18}
  ], 
  [ // 18
    { x: 6, y: 3},
    { x: 10, y: 3},
    { x: 14, y: 3},
    { x: 4, y: 6},
    { x: 8, y: 6},
    { x: 12, y: 6},
    { x: 16, y: 6},
    { x: 2, y: 9},
    { x: 6, y: 9},
    { x: 14, y: 9},
    { x: 18, y: 9},
    { x: 4, y: 12},
    { x: 8, y: 12},
    { x: 12, y: 12},
    { x: 16, y: 12},
    { x: 6, y: 15},
    { x: 10, y: 15},
    { x: 14, y: 15},
      ], 
  [ // 19
    { x: 6, y: 3},
    { x: 10, y: 3},
    { x: 14, y: 3},
    { x: 4, y: 6},
    { x: 8, y: 6},
    { x: 12, y: 6},
    { x: 16, y: 6},
    { x: 2, y: 9},
    { x: 6, y: 9},
    { x: 10, y: 9},
    { x: 14, y: 9},
    { x: 18, y: 9},
    { x: 4, y: 12},
    { x: 8, y: 12},
    { x: 12, y: 12},
    { x: 16, y: 12},
    { x: 6, y: 15},
    { x: 10, y: 15},
    { x: 14, y: 15},
      ], 
  [ // 20
    { x: 6, y: 3},
    { x: 10, y: 3},
    { x: 14, y: 3},
    { x: 4, y: 6},
    { x: 8, y: 6},
    { x: 12, y: 6},
    { x: 16, y: 6},
    { x: 2, y: 9},
    { x: 6, y: 9},
    { x: 10, y: 9},
    { x: 14, y: 9},
    { x: 18, y: 9},
    { x: 4, y: 12},
    { x: 8, y: 12},
    { x: 12, y: 12},
    { x: 16, y: 12},
    { x: 6, y: 15},
    { x: 10, y: 15},
    { x: 14, y: 15},
    { x: 10, y: 18}
      ],
  [ // 21
    { x: 6, y: 3},
    { x: 10, y: 3},
    { x: 14, y: 3},
    { x: 4, y: 6},
    { x: 8, y: 6},
    { x: 12, y: 6},
    { x: 16, y: 6},
    { x: 2, y: 9},
    { x: 6, y: 9},
    { x: 10, y: 9},
    { x: 14, y: 9},
    { x: 18, y: 9},
    { x: 4, y: 12},
    { x: 8, y: 12},
    { x: 12, y: 12},
    { x: 16, y: 12},
    { x: 6, y: 15},
    { x: 10, y: 15},
    { x: 14, y: 15},
    { x: 8, y: 18},
    { x: 12, y: 18}
      ],
];


    function generateZeros(num) {
        let zeros = '';
        for (let i = 0; i < num; i++) {
            zeros += '0';
        }
        return zeros;
    }

    const contextObject = {
        numDots, setNumDots,
        puzzleDigits, setPuzzleDigits,
        staticDigits, setStaticDigits, shareLink, setShareLink, imageName, imageSaved,
        areDigitsCorrect, setAreDigitsCorrect,
        moveList, setMoveList,
        colorMap, positionBase, digString2Dots, generateRandomString, createPuzzleId   
    };

    return (
        <AppContext.Provider value={contextObject}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
