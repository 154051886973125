// patternStorage.js

const STORAGE_KEY = 'SAVED_DOT_PATTERNS';

export const loadPatterns = () => {
  const storedPatterns = localStorage.getItem(STORAGE_KEY);
  return storedPatterns ? JSON.parse(storedPatterns) : [];
};


export const savePattern = (pattern, isIsometric) => {
  const currentPatterns = loadPatterns();
  const updatedPatterns = [...currentPatterns, pattern];
  localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedPatterns));
};

export const clearPatterns = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  export const clearAllPatterns = () => {
    localStorage.removeItem(STORAGE_KEY);
  };
    
export const removePattern = (indexToRemove) => {
  const currentPatterns = loadPatterns();
  const updatedPatterns = currentPatterns.filter((_, index) => index !== indexToRemove);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedPatterns));
};

export const searchPatternsByDotCount = (dotCount) => {
    const allPatterns = loadPatterns();
    return allPatterns.filter(pattern => pattern.dotCount === dotCount);
  };