import React, { useState, useEffect } from 'react';
import { Circle } from 'lucide-react';

const initialColorOptions = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00'];
const additionalColors = ['#FF00FF', '#00FFFF', '#FFA500', '#800080'];

const Partition = ({ patternData, onLoadRandomPattern }) => {
  const [colorOptions, setColorOptions] = useState(initialColorOptions);
  const [selectedColor, setSelectedColor] = useState(colorOptions[0]);
  const [dotColors, setDotColors] = useState([]);
  const [allColored, setAllColored] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  const [scale, setScale] = useState(1);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const svgSize = 500; // SVG container size

  useEffect(() => {
    if (patternData && patternData.pattern) {
      setDotColors(patternData.pattern.map(() => '#000000'));
      calculateScale(patternData.pattern, patternData.isIsometric);
    }
  }, [patternData]);

  const calculateScale = (pattern, isIsometric) => {
    if (pattern.length === 0) return;

    const xValues = pattern.map(p => p.x);
    const yValues = pattern.map(p => p.y);
    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    let width = maxX - minX;
    let height = maxY - minY;

    if (isIsometric) {
      // Adjust for isometric projection
      width = (width + height) * Math.sqrt(3) / 2;
      height = (width + height) / 2;
    }

    const maxDimension = Math.max(width, height);
    const newScale = (svgSize - 100) / maxDimension;
    setScale(newScale);

    setOffsetX((svgSize - width * newScale) / 2 - minX * newScale);
    setOffsetY((svgSize - height * newScale) / 2 - minY * newScale);
  };

  useEffect(() => {
    setAllColored(!dotColors.includes('#000000'));
  }, [dotColors]);

  useEffect(() => {
    const usedColors = new Set(dotColors);
    if (usedColors.size === colorOptions.length && colorOptions.length < initialColorOptions.length + additionalColors.length) {
      const newColor = additionalColors[colorOptions.length - initialColorOptions.length];
      setColorOptions(prevColors => [...prevColors, newColor]);
    }
  }, [dotColors, colorOptions]);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleDotClick = (index) => {
    const newDotColors = [...dotColors];
    newDotColors[index] = selectedColor;
    setDotColors(newDotColors);
  };

  const countColors = () => {
    return dotColors.reduce((acc, color) => {
      acc[color] = (acc[color] || 0) + 1;
      return acc;
    }, {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("User's answer:", userAnswer);
    // Here you can add logic to check the user's answer
  };

  const renderPattern = () => {
    if (!patternData || !patternData.pattern) return null;

    return patternData.pattern.map((pos, index) => {
      let x = pos.x;
      let y = pos.y;

      if (patternData.isIsometric) {
        // Convert to isometric projection
        const isoX = (x - y) * Math.sqrt(3) / 2;
        const isoY = (x + y) / 2;
        x = isoX;
        y = isoY;
      }

      return (
        <circle
          key={index}
          cx={x * scale + offsetX}
          cy={y * scale + offsetY}
          r={25 * (scale / 100)}
          fill={dotColors[index]}
          onClick={() => handleDotClick(index)}
          className="cursor-pointer"
        />
      );
    });
  };

  if (!patternData) return <div>No pattern selected</div>;


  return (
    <div className="flex p-4 gap-4">
      {/* Left column: Controls */}
      <div className="w-1/4">
        <h2 className="text-2xl font-bold mb-4">Controls</h2>
        <button 
          onClick={onLoadRandomPattern}
          className="w-full mb-4 bg-purple-500 text-white px-4 py-2 rounded"
        >
          Load Random Pattern
        </button>
        <div className="mb-4 grid grid-cols-2 gap-2">
          {colorOptions.map((color) => (
            <button
              key={color}
              style={{
                backgroundColor: color,
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: selectedColor === color ? '2px solid black' : 'none'
              }}
              onClick={() => handleColorSelect(color)}
            >
              <Circle 
                fill={selectedColor === color ? 'white' : 'transparent'} 
                color={selectedColor === color ? 'white' : 'black'}
                size={24} 
              />
            </button>
          ))}
        </div>
      </div>

      {/* Center column: Pattern */}
      <div className="w-1/2">
        <h2 className="text-2xl font-bold mb-4">Current Pattern</h2>
        <svg width={svgSize} height={svgSize} className="border border-gray-300">
          {renderPattern()}
        </svg>
      </div>

      {/* Right column: Color Distribution */}
      <div className="w-1/4">
        <h2 className="text-2xl font-bold mb-4">Color Distribution</h2>
        <div className="flex flex-wrap items-center space-x-2">
          {Object.entries(countColors()).reduce((acc, [color, count], index, array) => {
            if (color !== '#000000') {
              if (acc.length > 0) {
                acc.push(<span key={`plus-${index}`} className="text-xl">+</span>);
              }
              acc.push(
                <div
                  key={color}
                  className="w-10 h-10 rounded-full flex items-center justify-center text-white font-bold"
                  style={{ backgroundColor: color }}
                >
                  {count}
                </div>
              );
            }
            return acc;
          }, [])}
          <span className="text-xl">=</span>
          <input
            type="number"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            className="w-20 px-2 py-1 border border-gray-300 rounded"
            placeholder="?"
          />
          <button
            onClick={handleSubmit}
            className="ml-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Check
          </button>
        </div>
      </div>
    </div>
  );
};

export default Partition;