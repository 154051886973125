import React, { useRef, useState } from 'react';
import { useApp } from '../contexts/AppContext';
import StaticCanvas from './StaticCanvas';
import { Card, CardBody, CardFooter, CardHeader, Button, Link, useToast } from '@chakra-ui/react';

const PuzzleOutro = () => {
  const { 
    moveList, 
    shareLink 
  } = useApp();
  const canvasRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const toast = useToast();



  const shareMessage = `I completed this puzzle in ${moveList.length} moves!`;

  const handleTweet = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&url=${encodeURIComponent(shareLink)}`;
    window.open(tweetUrl, '_blank');
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);
  
  const handleWhatsApp = () => {
    const message = encodeURIComponent(shareMessage + ' ' + shareLink);
    let whatsappUrl = `https://wa.me/?text=${message}`;
    
    if (isIOS || isAndroid) {
      whatsappUrl = `whatsapp://send?text=${message}`;
    }
    
    window.open(whatsappUrl, '_blank');
  };

  const captureCanvas = () => {
    if (canvasRef.current) {
      const dataUrl = canvasRef.current.captureImage();
      setImageDataUrl(dataUrl);
      return dataUrl;
    }
  };

  const handleDownload = () => {
    const dataUrl = imageDataUrl || captureCanvas();
    if (dataUrl) {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'puzzle.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleCopyImage = async () => {
    if (!canvasRef.current) return;
    
    try {
      const canvas = canvasRef.current.getCanvas(); // Assume we've added a method to get the actual canvas element
      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      
      if (navigator.clipboard && navigator.clipboard.write) {
        await navigator.clipboard.write([
          new ClipboardItem({
            'image/png': blob
          })
        ]);
        toast({
          title: "Image copied",
          description: "The image has been copied to your clipboard as PNG.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Fallback for browsers that don't support clipboard.write
        const dataUrl = canvas.toDataURL('image/png');
        await navigator.clipboard.writeText(dataUrl);
        toast({
          title: "Image data copied",
          description: "The image data URL has been copied to your clipboard.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast({
        title: "Copy failed",
        description: "Failed to copy the image. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card>
        <CardHeader><h1>Congratulations!</h1></CardHeader>
        <CardBody>
          <StaticCanvas ref={canvasRef} />

          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
            <Button onClick={handleTweet} leftIcon={<img src="https://www.iconpacks.net/icons/2/free-twitter-logo-icon-2429-thumb.png" width="20px" alt="Twitter" />}>
              Share on Twitter
            </Button>
            <Button onClick={handleWhatsApp} leftIcon={<img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" width="20px" alt="WhatsApp" />}>
              Share on WhatsApp
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
            <Button onClick={handleDownload}>Download Image</Button>
            <Button onClick={handleCopyImage}>Copy Image Data</Button>
          </div>
          <p>You have completed the puzzle!</p>
          <p>You managed this in {moveList.length} moves</p>
        </CardBody>
        <CardFooter>
          <p>Use the link below to share your achievement with others or to try to get a better score:</p>
          <Link href={shareLink} color="blue.500">Share</Link>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PuzzleOutro;