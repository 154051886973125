import React, { useRef, useEffect, useReducer, useCallback } from 'react';
import Controls from './Controls';
import StaticCanvas from './StaticCanvas';
import { useApp } from '../contexts/AppContext';
import { Box, Flex, Text, Button } from '@chakra-ui/react';

// Define action types
const RESET_PUZZLE = 'RESET_PUZZLE';
const TOGGLE_BUTTON = 'TOGGLE_BUTTON';
const UNDO_MOVE = 'UNDO_MOVE';

// Reducer function
function puzzleReducer(state, action) {
  switch (action.type) {
    case RESET_PUZZLE:
      console.log(action.payload.newPuzzleId);
      return {
        ...state,
        puzzleDigits: generateZeros(state.numDots),
        freedots: generateConsecutiveArray(state.numDots),
        dotArrayRedraw: action.payload.digString2Dots(generateZeros(state.numDots), action.payload.positionBase[state.numDots], 25, action.payload.colorMap),
        activeNumbers: [],
        moveList: [], 
        shareLink: action.payload.baseUrl + '?puzzleId=' + action.payload.newPuzzleId

      };
    case TOGGLE_BUTTON:
      const { number, isActive } = action.payload;
      // Check if there are enough free dots
      if (isActive && number > state.freedots.length) {
        return state; // Return current state without changes
      }
      let newPuzzleDigits = state.puzzleDigits;
      let newFreedots = [...state.freedots];
      let newDotArrayRedraw = [...state.dotArrayRedraw];
      let newActiveNumbers = [...state.activeNumbers];
      let newMoveList = [{number, direction: isActive ? 'down' : 'up'}, ...state.moveList];

      if (isActive) {
        newActiveNumbers.push(number);
        for(let i = 0; i < number; i++) {
          const dotIndex = newFreedots[0];
          newPuzzleDigits = setCharAt(newPuzzleDigits, dotIndex, number.toString());
          newDotArrayRedraw[dotIndex] = {...newDotArrayRedraw[dotIndex], colour: action.payload.colorMap[number]};
          newFreedots = newFreedots.slice(1);
        }
      } else {
        newActiveNumbers = newActiveNumbers.filter(n => n !== number);
        for(let i = 0; i < newPuzzleDigits.length; i++) {
          if(newPuzzleDigits[i] === number.toString()) {
            newPuzzleDigits = setCharAt(newPuzzleDigits, i, "0");
            newDotArrayRedraw[i] = {...newDotArrayRedraw[i], colour: "black"};
            newFreedots = [i, ...newFreedots].sort((a, b) => a - b);
          }
        }
      }

      return {
        ...state,
        puzzleDigits: newPuzzleDigits,
        freedots: newFreedots,
        dotArrayRedraw: newDotArrayRedraw,
        activeNumbers: newActiveNumbers,
        moveList: newMoveList
      };
    case UNDO_MOVE:
      if (state.moveList.length === 0) return state;
      const newState = puzzleReducer(state, { type: RESET_PUZZLE, payload: action.payload });
      const movesToReplay = state.moveList.slice(1).reverse();
      return movesToReplay.reduce((currentState, move) => 
        puzzleReducer(currentState, { 
          type: TOGGLE_BUTTON, 
          payload: { ...action.payload, number: move.number, isActive: move.direction === 'down' } 
        }), newState);
    default:
      return state;
  }
}

export function Canvas() {
  const {
    numDots, setNumDots,
    staticDigits, setStaticDigits,
    areDigitsCorrect, setAreDigitsCorrect,
    colorMap, positionBase, digString2Dots, generateRandomString,
    setPuzzleDigits,  // Add this line to get the setPuzzleDigits function from context
    setMoveList,
    setShareLink,  // Add this line to get the setShareLink function from context
    createPuzzleId  // Add this function to generate new puzzleIds
  } = useApp();

  const baseUrl = window.location.origin + window.location.pathname; // Get the base URL


  const initialState = {
    numDots,
    puzzleDigits: generateZeros(numDots),
    freedots: generateConsecutiveArray(numDots),
    dotArrayRedraw: digString2Dots(generateZeros(numDots), positionBase[numDots], 25, colorMap),
    activeNumbers: [],
    moveList: [],
    shareLink: baseUrl + '?puzzleId=' + createPuzzleId(staticDigits)
  };

console.log({initialState});

  const [state, dispatch] = useReducer(puzzleReducer, initialState);

  const canvasRef = useRef(null);

    // Add this useEffect to update puzzleDigits in AppContext
    useEffect(() => {
      setPuzzleDigits(state.puzzleDigits);
      setMoveList(state.moveList);
      setShareLink(state.shareLink);
    }, [state.puzzleDigits, state.moveList, state.shareLink, setPuzzleDigits, setMoveList, setShareLink]);
  
  
    useEffect(() => {
      if (state.puzzleDigits === staticDigits && !areDigitsCorrect) {
        setAreDigitsCorrect(true);
      }
    }, [state.puzzleDigits, staticDigits, setAreDigitsCorrect, areDigitsCorrect]);

    const handleButtonToggle = useCallback((number, isActive) => {
      const prevState = state;
      dispatch({ type: 'TOGGLE_BUTTON', payload: { number, isActive, colorMap } });
      // Check if the state changed after dispatch
      return prevState !== state;
    }, [state, colorMap]);

    const handleUndo = useCallback(() => {
      dispatch({ type: 'UNDO_MOVE', payload: { digString2Dots, positionBase, colorMap } });
    }, [digString2Dots, positionBase, colorMap]);

  useEffect(() => {
    drawCanvas();
  }, [state.dotArrayRedraw]);



  const drawCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.canvas.width = 500;
    context.canvas.height = 500;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = "grey";
    context.fillStyle = "lightgrey";
    context.lineWidth = 15;
    context.beginPath();
    context.roundRect(10, 10, 480, 480, 20);
    context.stroke();
    context.fill();

    let radius = 30; // Arc radius
    let startAngle = 0; // Starting point on circle
    let endAngle = Math.PI * 2; // End point on circle
    for (let i = state.dotArrayRedraw.length - 1; i >= 0; i--) {
      context.beginPath();
      context.fillStyle = state.dotArrayRedraw[i].colour;
      context.arc(state.dotArrayRedraw[i].x, state.dotArrayRedraw[i].y, radius, startAngle, endAngle);
      context.fill();
      context.closePath();
    }
  }, [state.dotArrayRedraw]);

  return (
    <Flex direction={["column", "column", "row"]} justify="center" align="center" wrap="wrap" p={4}>
      <Box mr={[0, 0, 8]} mb={[8, 8, 0]}>
        <Flex direction="column" align="center">
          <Box mb={4}>
            <canvas ref={canvasRef} width={500} height={500} />
          </Box>
          <Text mb={4}>Moves: {state.moveList.length}</Text>
          <Controls onButtonToggle={handleButtonToggle} activeNumbers={state.activeNumbers} />
          <Button onClick={handleUndo} mt={4} isDisabled={state.moveList.length === 0}>
            Undo Last Move
          </Button>
        </Flex>
      </Box>
      <Box>
        <StaticCanvas />
      </Box>
    </Flex>
  );
}

function generateZeros(num) {
  return '0'.repeat(num);
}

function generateConsecutiveArray(num) {
  return Array.from({ length: num }, (_, i) => i);
}

function setCharAt(str, index, chr) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}