import {useState} from 'react';
import { useApp } from '../contexts/AppContext';
import { Link } from 'react-router-dom';
import { Canvas } from './Canvas';
import { Box, Container } from '@chakra-ui/react';


export default function Puzzle() {
    const {
        numDots, setNumDots,
        puzzleDigits, setPuzzleDigits,
        staticDigits, setStaticDigits,
        areDigitsCorrect,
        colorMap, positionBase, digString2Dots, generateRandomString    
        } = useApp();
    
    const [puzzleCode, setPuzzleCode] = useState('');
    const [puzzleCodeError, setPuzzleCodeError] = useState('');
    const [puzzleCodeErrorText, setPuzzleCodeErrorText] = useState('');
    const [puzzleCodeValid, setPuzzleCodeValid] = useState(false);
    
    function validatePuzzleCode() {
        let error = false;
        let errorText = '';
        let valid = false;
    
        if (puzzleCode.length !== numDots) {
        error = true;
        errorText = `Puzzle code must be ${numDots} digits long.`;
        } else {
        for (let i = 0; i < numDots; i++) {
            if (puzzleCode[i] < 0 || puzzleCode[i] > numDots - 1) {
            error = true;
            errorText = `Puzzle code must contain only digits between 0 and ${numDots - 1}.`;
            break;
            }
        }
        }
    
        if (!error) {
        valid = true;
        }
    
        setPuzzleCodeError(error);
        setPuzzleCodeErrorText(errorText);
        setPuzzleCodeValid(valid);
    }
    
    function handlePuzzleCodeChange(event) {
        setPuzzleCode(event.target.value);
        setPuzzleCodeValid(false);
    }
    
    function handlePuzzleCodeSubmit(event) {
        event.preventDefault();
        validatePuzzleCode();
    }
    
    function handlePuzzleCodeReset(event) {
        event.preventDefault();
        setPuzzleCode('');
        setPuzzleCodeValid(false);
    }
    
    function handlePuzzleCodeRandom(event) {
        event.preventDefault();
        setPuzzleCode(generateRandomString(numDots));
        setPuzzleCodeValid(false);
    }
    
    function handlePuzzleCodeSet(event) {
        event.preventDefault();
        if (puzzleCodeValid) {
        setPuzzleDigits(puzzleCode);
        }
    }
    
    return (
        <Container maxW="container.xl" centerContent>
          <Box width="100%" py={8}>
            <Canvas />
          </Box>
        </Container>
      );
    }