import React, { useState, useEffect } from 'react';
import PatternGenerator from './PatternGenerator';
import Partition from './Partition';
import { loadPatterns, clearAllPatterns } from './patternStorage'; // Assume this function exists to load saved patterns

const PartitionPatternManager = () => {
  const [selectedPattern, setSelectedPattern] = useState(null);
  const [showPartition, setShowPartition] = useState(false);
  const [savedPatterns, setSavedPatterns] = useState([]);

  const loadSavedPatterns = () => {
    setSavedPatterns(loadPatterns());
  };

  useEffect(() => {
    loadSavedPatterns();
  }, []);

  const handleSelectPattern = (patternData) => {
    setSelectedPattern(patternData);
    setShowPartition(true);
  };

  const handleRandomPattern = () => {
    if (savedPatterns.length > 0) {
      const randomIndex = Math.floor(Math.random() * savedPatterns.length);
      setSelectedPattern(savedPatterns[randomIndex]);
      setShowPartition(true);
    }
  };

  const handleClearPatterns = () => {
    if (window.confirm("Are you sure you want to clear all saved patterns? This action cannot be undone.")) {
      clearAllPatterns();
      setSavedPatterns([]);
      alert("All saved patterns have been cleared.");
    }
  };

  const handleBackToGenerator = () => {
    setShowPartition(false);
  };

  return (
    <div>
      {!showPartition ? (
        <div>
            <PatternGenerator 
            onSelectPattern={handleSelectPattern}
            savedPatterns={savedPatterns}
            onPatternsUpdated={loadSavedPatterns}
          />
          <div className="mt-4 flex justify-between">
            <button 
                onClick={handleRandomPattern}
                className="mt-4 bg-purple-500 text-white px-4 py-2 rounded"
                disabled={savedPatterns.length === 0}
            >
                Load Random Pattern
            </button>
            <button 
                onClick={handleClearPatterns}
                className="bg-red-500 text-white px-4 py-2 rounded"
                >
                Clear All Saved Patterns
                </button>
            </div>
        </div>
      ) : (
        <div>
          <button 
            onClick={handleBackToGenerator}
            className="mb-4 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Back to Pattern Generator
          </button>
          <Partition 
            patternData={selectedPattern} 
            onLoadRandomPattern={handleRandomPattern}
          />
        </div>
      )}
    </div>
  );
};

export default PartitionPatternManager;