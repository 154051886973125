import './App.css';
import AppContextProvider, { AppContext } from './contexts/AppContext';
import Puzzle from './components/Puzzle';
import PuzzleOutro from './components/PuzzleOutro';
import Partition from './components/Partition';
import PatternGenerator from './components/PatternGenerator';
import PartitionPatternManager from './components/PartitionPatternManager';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// const dotarray = [
//   { x: 250, y: 75, colour: 'black' },
//   { x: 200, y: 150, colour: 'black' },
//   { x: 300, y: 150, colour: 'black' },
//   { x: 150, y: 225, colour: 'black' },
//   { x: 250, y: 225, colour: 'black' },
//   { x: 350, y: 225, colour: 'black' },
//   { x: 200, y: 300, colour: 'black' },
//   { x: 300, y: 300, colour: 'black' },
//   { x: 250, y: 375, colour: 'black' }
// ];


  // Is this possible to solve? 3342444312 , 312332 Or should there be a button to say it's impossible?  What data storage is needed to keep track of the responses to each of the possible arrangements?


export default function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>  
        <ChakraProvider>    
          <Routes>
            <Route path="/" element={<Puzzle />} />
            <Route path="/:puzzleCode" element={<Puzzle />} />
            <Route path="/outro" element= {<PuzzleOutro />} />
            <Route path="/partition" element= {<Partition />} />
            <Route path="/pattern-gen" element= {<PatternGenerator />} />
            <Route path="/partition-pattern" element= {<PartitionPatternManager />} />

          </Routes>
        </ChakraProvider>
      </AppContextProvider>      
    </BrowserRouter>


  );
}

