import React, { useState, useEffect } from 'react';
import { Circle, RefreshCw, Save, Search } from 'lucide-react';

// Assume we have a way to load and save patterns to local storage or a database
import { loadPatterns, savePattern, searchPatternsByDotCount } from './patternStorage';

const generateRandomPattern = (numDots, gridSize, isGrouped, symmetryX, symmetryY, isIsometric) => {
    const pattern = [];
    const usedPositions = new Set();
    const margin = 1; // Margin to avoid edges
  
    const addDot = (x, y) => {
      if (x >= margin && x < gridSize - margin && y >= margin && y < gridSize - margin) {
        const posKey = `${x},${y}`;
        if (!usedPositions.has(posKey)) {
          pattern.push({ x, y });
          usedPositions.add(posKey);
          
          if (symmetryX || symmetryY) {
            let symX = x, symY = y;
            
            if (symmetryX) {
              symX = isIsometric 
                ? gridSize - 1 - x + (gridSize - 1 - y) - y 
                : gridSize - 1 - x;
            }
            
            if (symmetryY) {
              symY = isIsometric
                ? gridSize - 1 - y + x - (gridSize - 1 - x)
                : gridSize - 1 - y;
            }
            
            const symKey = `${symX},${symY}`;
            if (!usedPositions.has(symKey) && 
                symX >= margin && symX < gridSize - margin && 
                symY >= margin && symY < gridSize - margin) {
              pattern.push({ x: symX, y: symY });
              usedPositions.add(symKey);
            }
          }
        }
      }
    };
  
    const tryAddDot = () => {
      const x = Math.floor(Math.random() * (gridSize - 2 * margin)) + margin;
      const y = Math.floor(Math.random() * (gridSize - 2 * margin)) + margin;
      addDot(x, y);
    };
  
    if (isGrouped) {
      // Start with a central dot
      const centerX = Math.floor(gridSize / 2);
      const centerY = Math.floor(gridSize / 2);
      addDot(centerX, centerY);
  
      // Add subsequent dots adjacent to existing ones
      while (pattern.length < numDots) {
        const baseDot = pattern[Math.floor(Math.random() * pattern.length)];
        const direction = Math.floor(Math.random() * 4); // 0: up, 1: right, 2: down, 3: left
        let newX = baseDot.x;
        let newY = baseDot.y;
  
        switch (direction) {
          case 0: newY = Math.max(margin, newY - 1); break;
          case 1: newX = Math.min(gridSize - 1 - margin, newX + 1); break;
          case 2: newY = Math.min(gridSize - 1 - margin, newY + 1); break;
          case 3: newX = Math.max(margin, newX - 1); break;
        }
  
        addDot(newX, newY);
      }
    } else {
      // For non-grouped patterns, keep trying to add dots until we reach the desired number
      while (pattern.length < numDots) {
        tryAddDot();
      }
    }
  
    return pattern;
  };

  const PatternGenerator = ({ onSelectPattern }) => {
    const [currentPattern, setCurrentPattern] = useState([]);
    const [numDots, setNumDots] = useState(15);
    const [gridSize, setGridSize] = useState(20);
    const [isGrouped, setIsGrouped] = useState(false);
    const [symmetryX, setSymmetryX] = useState(false);
    const [symmetryY, setSymmetryY] = useState(false);
    const [isIsometric, setIsIsometric] = useState(false);
    const [savedPatterns, setSavedPatterns] = useState([]);
    const [searchDotCount, setSearchDotCount] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchPatterns = async () => {
        try {
          const patterns = await loadPatterns();
          setSavedPatterns(patterns || []);
        } catch (err) {
          console.error("Failed to load patterns:", err);
          setError("Failed to load saved patterns. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchPatterns();
    }, []);

    const handlePatternSelect = (patternData) => {
        onSelectPattern(patternData);
      };

  const generateNewPattern = () => {
    let adjustedGridSize = gridSize;
    if ((symmetryX || symmetryY) && gridSize % 2 === 0) {
      adjustedGridSize++; // Ensure odd grid size for symmetry
    }
    const newPattern = generateRandomPattern(numDots, adjustedGridSize, isGrouped, symmetryX, symmetryY, isIsometric);
    console.log("New pattern generated:", newPattern); 
    setCurrentPattern(newPattern);
  };

  const handleSavePattern = () => {
    const patternMetadata = {
      pattern: currentPattern,
      dotCount: currentPattern.length,
      gridSize,
      isGrouped,
      symmetryX,
      symmetryY,
      isIsometric
    };
    const newSavedPatterns = [...savedPatterns, patternMetadata];
    setSavedPatterns(newSavedPatterns);
    savePattern(patternMetadata, isIsometric);
  };

  const handleSearch = async () => {
    try {
      const results = await searchPatternsByDotCount(parseInt(searchDotCount));
      setSavedPatterns(results || []);
    } catch (err) {
      console.error("Failed to search patterns:", err);
      setError("Failed to search patterns. Please try again.");
    }
  };

  const renderDot = (pos, index, svgSize, gridSize) => {
    if (isIsometric) {
      // Convert grid coordinates to isometric view
      const isoX = (pos.x - pos.y) * Math.sqrt(3) / 2;
      const isoY = (pos.x + pos.y) / 2;
      
      // Scale and translate to fit SVG
      const scale = svgSize / (gridSize * Math.sqrt(3));
      const translateX = svgSize / 2;
      const translateY = svgSize / 4;
      
      return (
        <circle
          key={index}
          cx={isoX * scale + translateX}
          cy={isoY * scale + translateY}
          r="5"
          fill="black"
        />
      );
    } else {
      return (
        <circle
          key={index}
          cx={pos.x * (svgSize / gridSize)}
          cy={pos.y * (svgSize / gridSize)}
          r="5"
          fill="black"
        />
      );
    }
  };
  
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex p-4 gap-4">
      {/* Left column: Controls */}
      <div className="w-1/4">
        <h2 className="text-2xl font-bold mb-4">Controls</h2>
        <div className="space-y-2">
          <label className="block">
            Number of Dots:
            <input
              type="number"
              value={numDots}
              onChange={(e) => setNumDots(parseInt(e.target.value))}
              className="w-full mt-1 p-1 border rounded"
            />
          </label>
          <label className="block">
            Grid Size:
            <input
              type="number"
              value={gridSize}
              onChange={(e) => setGridSize(parseInt(e.target.value))}
              className="w-full mt-1 p-1 border rounded"
            />
          </label>
          <label className="block">
            <input
              type="checkbox"
              checked={isGrouped}
              onChange={(e) => setIsGrouped(e.target.checked)}
              className="mr-2"
            />
            Generate Grouped Dots
          </label>
          <label className="block">
            <input
              type="checkbox"
              checked={symmetryX}
              onChange={(e) => setSymmetryX(e.target.checked)}
              className="mr-2"
            />
            Symmetry in X-axis
          </label>
          <label className="block">
            <input
              type="checkbox"
              checked={symmetryY}
              onChange={(e) => setSymmetryY(e.target.checked)}
              className="mr-2"
            />
            Symmetry in Y-axis
          </label>
          {/* <label className="block">
            <input
              type="checkbox"
              checked={isIsometric}
              onChange={(e) => setIsIsometric(e.target.checked)}
              className="mr-2"
            />
            Use Isometric Grid
          </label> */}
        </div>
        <div className="mt-4 space-y-2">
          <button
            onClick={generateNewPattern}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center"
          >
            <RefreshCw className="mr-2" size={16} />
            Generate New Pattern
          </button>
          <button
            onClick={handleSavePattern}
            className="w-full bg-green-500 text-white px-4 py-2 rounded flex items-center justify-center"
          >
            <Save className="mr-2" size={16} />
            Save Current Pattern
          </button>
        </div>
      </div>

      {/* Center column: Main Pattern */}
      <div className="w-1/2">
        <h2 className="text-2xl font-bold mb-4">Current Pattern</h2>
        <div className="w-full aspect-square border border-gray-300">
          <svg width="100%" height="100%" viewBox="0 0 500 500">
            {currentPattern.map((pos, index) => renderDot(pos, index, 500, gridSize))}
          </svg>
        </div>
      </div>

      {/* Right column: Saved Patterns */}
      <div className="w-1/4">
        <h2 className="text-2xl font-bold mb-4">Saved Patterns</h2>
        <div className="mb-4">
          <input
            type="number"
            value={searchDotCount}
            onChange={(e) => setSearchDotCount(e.target.value)}
            placeholder="Search by dot count"
            className="w-full p-1 border rounded"
          />
          <button
            onClick={handleSearch}
            className="w-full mt-2 bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center"
          >
            <Search className="mr-2" size={16} />
            Search
          </button>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {savedPatterns.map((patternData, patternIndex) => (
            <div 
              key={patternIndex} 
              className="border p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handlePatternSelect(patternData)}
            >              
            <svg width="100%" height="100%" viewBox="0 0 100 100">
                {patternData.pattern && patternData.pattern.map((pos, dotIndex) => 
                  renderDot(pos, dotIndex, 100, patternData.gridSize)
                )}
              </svg>
              <p className="text-center text-sm">Dots: {patternData.dotCount}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatternGenerator;