import React, { useState, useEffect } from 'react';
import { useApp } from '../contexts/AppContext';

export default function Number({ value, onToggle, isActive: propIsActive }) {
  const { areDigitsCorrect } = useApp();
  const [isActive, setIsActive] = useState(propIsActive);

  useEffect(() => {
    setIsActive(false);
  }, [areDigitsCorrect]);

  useEffect(() => {
    setIsActive(propIsActive);
  }, [propIsActive]);
  
  const colorMap = {
    1: 'white',
    2: 'red',
    3: 'green',
    4: 'yellow',
    5: 'blue',
    6: 'orange',
    7: 'purple',
    8: 'pink',
    9: 'brown',
    // ... Add colors for other numbers
  };
  const color = colorMap[value];

  const handleClick = () => {
    const newActiveState = !isActive;
      if (onToggle(value, newActiveState)){
          setIsActive(newActiveState);
      }

  };

  return (
    <button
      onClick={handleClick}
      style={{
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundColor: isActive ? color : 'grey',
        borderColor: color,
        borderWidth: '2px',
        fontSize: '2rem',
        fontWeight: 'bold', 
        color: isActive ? 'lightgrey' : color,
      }}
    >
      {value}
    </button>
  );
}

